<!--  -->
<template>
  <div
    class="home page-template page-template-template-home page-template-template-home-php page page-id-11 wpb-js-composer js-comp-ver-6.4.2 vc_responsive"
  >
    <Headers></Headers>
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="main" class="site-main" role="main">
          <div class="content_container culture">
            <!-- 头部banner图 -->
            <div class="section_banner_container" style="margin-bottom: 20px">
              <div class="section_banner">
                <div class="container">
                  <div class="row">
                    <div class="col-xs-12 col-sm-11">
                      <div class="section_banner_content">
                        <h1 class="section_banner_heading">盈喜文化</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 新闻列表 -->
            <div class="inside-page container">
              <div class="in-culture">
                <!-- <div class="culture-line">
                  <div class="commonweb">
                    <h4
                      class="culture-title"
                      style="font-size: 27px; margin-bottom: 10px"
                    >
                      企业文化
                    </h4>
                    <div class="cultureRow-one">
                      <dl class="clearfix">
                        <dt>
                          <div class="culone-image">
                            <img src="../../assets/culture.png" />
                          </div>
                        </dt>
                        <dd>
                          <ul class="culone-ul" style="font-size: 18px">
                            <li>
                              <span class="title">企业愿景：</span
                              ><span class="text"
                                >让盈喜集团成为健康、舒适、时尚服饰的引领者</span
                              >
                            </li>
                            <li>
                              <span class="title">企业使命：</span
                              ><span class="text"
                                >以合作共赢的理念，服务大众、服务社会</span
                              >
                            </li>
                            <li>
                              <span class="title">核心价值观：</span
                              ><span class="text"
                                >客户至上、团队合作、面对变化、诚信、激情、敬业</span
                              >
                            </li>
                          </ul>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div> -->
                <!-- 遍历数据 -->
                <div class="culture-line" v-html="content"></div>
                <!-- <div class="culture-line">
                  <div class="commonweb">
                    <h4
                      class="culture-title"
                      style="font-size: 27px; margin-bottom: 10px"
                    >
                      经营理念
                    </h4>
                    <div class="cultureRow-two">
                      <div class="cultwo">
                        <div class="font16-lh26">
                          <div class="font16-lh26" style="font-size: 18px">
                            <p>
                              &nbsp; &nbsp; &nbsp; &nbsp;
                              稳健经营的前提下，客户价值就是企业的价值取向。注重企业口碑和长远发展，合作共赢，深入理解客户理念，让客户利益做大化。关注并深刻理解用户需求，不断以卓越的产品和服务满足用户需求。重视与用户的情感沟通，尊重用户感受，与用户共成长。
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="culture-line">
                  <div class="commonweb">
                    <h4
                      class="culture-title"
                      style="font-size: 27px; margin-bottom: 10px"
                    >
                      管理理念
                    </h4>
                    <div class="cultureRow-two">
                      <div class="cultwo">
                        <div class="font16-lh26">
                          <div class="font16-lh26" style="font-size: 18px">
                            <p>
                              &nbsp; &nbsp; &nbsp;
                              &nbsp;只要付出，就会有收获，只要愿意与企业共同成长，就会前途无量，只要足够优秀，就会拥有属于你自己的舞台。为员工提供良好的工作环境和激励机制，识别人才、培养人才，重视人才。充分尊重和信任员工，鼓励学习和完善职业发展通道，打造高效和谐有爱的工作环境。
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <!-- #content -->
    <Footers></Footers>
  </div>
</template>

<script>
import Headers from "./../../components/header/header.vue";
import Footers from "./../../components/footer/footer.vue";
import { queryPage } from "../../util/api.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Headers, Footers },
  metaInfo() {
    return {
      title: "盈喜集团企业文化_关于盈喜 - 盈喜集团股份有限公司|ATXG",
      meta: [
        {
          name: "keywords",
          content: "盈喜集团企业文化，盈喜愿景使命，盈喜企业价值观，ATXG",
        },
        {
          name: "description",
          content:
            "盈喜集团根据企业发展使命和愿景，塑造了客户至上、团队合作、诚信敬业的企业核心价值观，通过科学和以人为本的经营及管理理念，为企业成长提供精神动力。",
        },
      ],
    };
  },
  data() {
    //这里存放数据
    return {
      // 内容
      content: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 请求内容
    async init() {
      const { data: res } = await this.$http.get(queryPage, {
        params: { code: "culture" },
      });
      console.log(res);
      this.content = res.result.content;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.init();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.culture {
  text-align: left;
  .section_banner {
    background-image: url(../../../public/home/img/bg-section-banner-contact.jpg);
  }
  .in-culture {
    width: 100%;
    overflow: hidden;
  }

  .culture-line {
    width: 100%;
    padding-bottom: 20px;
  }

  .culture-title {
    color: #0055a5;
    font-size: 16px;
    line-height: 1.3;
    text-align: center;
    font-weight: bold;
  }

  .cultureRow-one {
    width: 100%;
    padding-top: 15px;
  }

  .cultureRow-one dl {
    width: 100%;
  }

  .cultureRow-one dl dt {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 25px 15px 20px 0;
  }

  .cultureRow-one dl dt:after {
    content: "";
    position: absolute;
    right: 0;
    height: 100%;
    width: 235px;
    top: 0;
    z-index: 1;
    // background: url(../images/cdian01.png) no-repeat scroll 0 0;
    -o-background-size: cover;
    background-size: cover;
  }

  .cultureRow-one dl dd {
    width: 100%;
    padding-top: 15px;
  }

  .culone-image {
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 2;
  }

  .culone-image img {
    display: block;
    width: 100%;
  }

  .culone-ul {
    width: 100%;
    font-size: 14px;
    color: #737373;
    line-height: 38px;
  }

  .culone-ul li .title {
    float: left;
    color: #333333;
    min-width: 120px;
  }

  .culone-ul li .text {
    display: block;
    overflow: hidden;
  }

  .cultureRow-two {
    width: 100%;
    padding-top: 15px;
  }

  .font16-lh26 {
    font-size: 14px;
    color: #666;
    line-height: 24px;
  }
  @media (min-width: 768px) {
    .inside-page {
      padding-top: 35px;
    }
  }

  @media (min-width: 1024px) {
    .cultureRow-one dl dt {
      width: 50% !important;
      overflow: hidden;
      position: relative;
      padding: 44px 30px 26px 60px;
      float: right;
    }
    .inside-nav-wrapper {
      padding-left: 45px;
      padding-right: 45px;
    }
    .inside-nav-swiper li:hover {
      background-color: #0055a5;
    }
    .inside-nav-swiper li:hover a {
      color: #fff;
    }
    .inside-nav-botton {
      display: none;
      position: absolute;
      width: 45px;
      top: 0;
      height: 100%;
      cursor: pointer;
      background-color: #d9d9d9;
      -webkit-transition: all 0.3s;
      -o-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
    }
    .inside-nav-botton:hover {
      background-color: #cac9c9;
    }
    .inside-nav-prev {
      left: 0;
    }
    .inside-nav-prev:after {
      content: "";
      width: 16px;
      height: 16px;
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      position: absolute;
      -webkit-transform: translate(-50%, -50%) rotate(45deg);
      -moz-transform: translate(-50%, -50%) rotate(45deg);
      -ms-transform: translate(-50%, -50%) rotate(45deg);
      -o-transform: translate(-50%, -50%) rotate(45deg);
      transform: translate(-50%, -50%) rotate(45deg);
      top: 50%;
      left: 50%;
      margin-left: 3px;
    }
    .inside-nav-next {
      right: 0;
    }
    .inside-nav-next:after {
      content: "";
      width: 16px;
      height: 16px;
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      position: absolute;
      -webkit-transform: translate(-50%, -50%) rotate(-135deg);
      -moz-transform: translate(-50%, -50%) rotate(-135deg);
      -ms-transform: translate(-50%, -50%) rotate(-135deg);
      -o-transform: translate(-50%, -50%) rotate(-135deg);
      transform: translate(-50%, -50%) rotate(-135deg);
      top: 50%;
      left: 50%;
      margin-left: -3px;
    }
    .inside-nav-botton.swiper-button-disabled:after {
      border-color: #454545;
    }
    .inside-nav-swiper ul {
      font-size: 0;
      text-align: center;
      display: block;
    }
    .inside-nav-swiper ul li {
      line-height: 56px;
      display: inline-block;
      vertical-align: top;
      min-width: 155.6px;
    }
    .inside-nav-swiper ul li a {
      font-size: 15px;
      padding: 0px;
    }
    .inside-nav-swiper ul.flex {
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: -moz-box;
      display: flex;
    }
    .inside-page {
      padding-top: 50px;
      padding-bottom: 55px;
    }
    .full-inside-nav {
      height: 56px;
    }
  }

  @media (min-width: 1200px) {
    .cultureRow-one dl dt {
      padding: 0px;
      padding-left: 80px;
    }
    .inside-nav-swiper ul li {
      line-height: 70px;
      min-width: 166.667px;
    }
    .inside-nav-swiper ul li a {
      font-size: 16px;
    }
    .inside-page {
      padding-top: 65px;
      padding-bottom: 75px;
    }
    .full-inside-nav {
      height: 70px;
    }
  }

  .font14-lh24 {
    font-size: 14px;
    line-height: 24px;
    color: #666;
  }

  .in-chairman {
    width: 100%;
  }

  .in-chairman dl dt {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .in-chairman dl dt img {
    display: block;
    width: 100%;
  }

  .in-chairman dl dd {
    width: 100%;
    padding-top: 20px;
  }

  .chairman-text-title {
    color: #0055a5;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .nobtpadding {
    padding-bottom: 0;
  }

  .his-datetab {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    overflow: hidden;
  }

  .his-datetab ul li {
    padding: 0;
    text-align: center;
    border: 1px solid #d7d7d7;
    line-height: 42px;
    margin-bottom: 10px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }

  .his-datetab ul li a {
    display: block;
    color: #868686;
    font-weight: bold;
    font-size: 15px;
    width: 100%;
  }

  .his-datetab ul li.cur,
  .his-datetab ul li:hover {
    background-color: #0055a5 !important;
    border-color: #0055a5 !important;
  }

  .his-datetab ul li.cur a,
  .his-datetab ul li:hover a {
    color: #fff;
  }

  .in-history {
    width: 100%;
    overflow: hidden;
  }

  .history-ul {
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .history-ul:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: #bfbfbf;
    top: 0;
    left: 6px;
    z-index: -1;
  }

  .history-ul li {
    width: 100%;
    margin-bottom: 10px;
  }

  .history-ul li .con {
    padding-left: 40px;
  }

  .history-ul li .con .title {
    font-size: 16px;
    color: #0055a5;
    line-height: 28px;
    font-weight: bold;
    position: relative;
  }

  .history-ul li .con .title:after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    // background-image: url(../images/yuanyuan.png);
    background-repeat: no-repeat;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    left: -39px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .history-ul li .con .title:before {
    content: "";
    position: absolute;
    width: 11px;
    height: 9px;
    // background-image: url(../images/leftleft.png);
    background-repeat: no-repeat;
    -o-background-size: cover;
    background-size: cover;
    background-position: center center;
    left: -20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .history-ul li .con .infp {
    color: #333333;
    line-height: 22px;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .history-ul li .con .imgmain {
    width: 100%;
  }

  .history-ul li .con .img {
    margin-left: -0.9%;
    margin-right: -0.9%;
    font-size: 0;
  }

  .history-ul li .con .img img {
    display: inline-block;
    vertical-align: bottom;
    width: 98.2%;
    margin-left: 0.9%;
    margin-right: 0.9%;
    margin-bottom: 10px;
  }

  .history-ul li .conmain {
    width: 100%;
    position: relative;
    z-index: 2;
  }

  @media (min-width: 768px) {
    .in-history ul {
      padding-top: 45px;
      padding-bottom: 60px;
    }
    .history-ul li .con .img img {
      width: 48.2%;
    }
  }

  @media (min-width: 1024px) {
    .in-history ul {
      padding-top: 75px;
      padding-bottom: 150px;
    }
    .history-ul:after {
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);
    }
    .history-ul li {
      margin-bottom: 20px;
    }
    .history-ul li .con {
      padding: 0px;
    }
    .history-ul li .con .title {
      font-size: 18px;
      line-height: 28px;
    }
    .history-ul li .con .infp {
      font-size: 16px;
      margin-top: 10px;
      margin-bottom: 15px;
    }
    .history-ul li:nth-child(odd) {
      padding-right: 50%;
    }
    .history-ul li:nth-child(odd) .con {
      padding-right: 50px;
    }
    .history-ul li:nth-child(odd) .con .title {
      text-align: right;
    }
    .history-ul li:nth-child(odd) .con .title:after {
      left: auto;
      right: -56px;
    }
    .history-ul li:nth-child(odd) .con .title:before {
      left: auto;
      right: -25px;
      // background-image: url(../images/rightright.png);
    }
    .history-ul li:nth-child(odd) .con .infp {
      text-align: right;
    }
    .history-ul li:nth-child(even) {
      padding-left: 50%;
    }
    .history-ul li:nth-child(even) .con {
      padding-left: 50px;
    }
    .history-ul li:nth-child(even) .con .title {
      text-align: left;
    }
    .history-ul li:nth-child(even) .con .title:after {
      right: auto;
      left: -56px;
    }
    .history-ul li:nth-child(even) .con .title:before {
      right: auto;
      left: -25px;
    }
    .history-ul li:nth-child(even) .con .infp {
      text-align: left;
    }
  }

  @media (min-width: 1200px) {
    .in-history ul {
      padding-top: 100px;
      padding-bottom: 200px;
    }
    .history-ul li .con .title {
      font-size: 24px;
      line-height: 32px;
      font-weight: normal;
    }
    .history-ul li .con .infp {
      margin-top: 20px;
      margin-bottom: 18px;
    }
  }
}
</style>